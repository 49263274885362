import * as React from "react";
import { useTranslation } from "react-i18next";
import useGetCountries from "../../swr/Countries";
import _ from "lodash";
import TwoStepVerificationSetUp from "./TwoStepVerificationSetUp";
import TwoStepVerificationPhoneNum from "./TwoStepVerificationPhoneNum";
import TwoStepVerificationPhoneNumVerify from "./TwoStepVerificationPhoneNumVerify";
import { useState } from "react";
import AuthenticatorApp from "./AuthenticatorApp";
import EditTwoStepVerificationSetUp from "./EditTwoStepVerificationSetUp";
import {
  AccountClient,
  TwoFactorProvider,
  UserPhoneNumber,
  UserPhoneNumberDto,
} from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";

import toast, { Toaster } from "react-hot-toast";
import Loading from "../../components/loading";
import classNames from "classnames";

type propType = {
  hasPassword: boolean;
};

const TwoStepVerificationForm = (props: propType) => {
  const { t } = useTranslation();
  const [qrcode, setQrcode] = useState("");
  const [step, setStep] = useState<number>(1);
  const [phoneNumber, setPhoneNumber] = useState<UserPhoneNumberDto>(null);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState<boolean>(null);
  const [twoFactorProvider, setTwoFactorProvider] =
    useState<TwoFactorProvider>(null);
  const [loading, setLoading] = useState(false);
  const [showTimerResendCode, setShowTimerResendCode] = useState(false);

  const isMobile = window.innerWidth < 768;

  React.useEffect(() => {
    if (step == 1) {
      checkTwoFactorEnabled();
    }
  }, [step]);

  // console.log("twoFactorEnabled", twoFactorEnabled);

  // const checkValidate = (values) => {
  //   let error = {} as any;

  //   if (!values.phoneCode || !values.phoneNumber || values.phoneNumber == "") {
  //     error.phoneNumber = "Enter a phone number";
  //   } else if (!/^\d+$/.test(values.phoneNumber)) {
  //     error.phoneNumber = "The phone number is not valid";
  //   }
  //   if (!values.type) {
  //     error.type = "Select a type";
  //   }
  //   return error;
  // };

  const checkTwoFactorEnabled = async () => {
    const client = new AccountClient(undefined, axiosInstance);
    setLoading(true);
    try {
      var res = await client.getTwoFactorEnabled();
      setTwoFactorEnabled(res.isEnabled);
      setTwoFactorProvider(res.provider);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      {loading ? (
        <Loading />
      ) : step == 1 ? (
        <>
          {twoFactorEnabled == null ? (
            <></>
          ) : !twoFactorEnabled ? (
            <div className="space-y-6  md:col-span-9 md:px-0 lg:col-span-8 2xl:col-span-9">
              <div
                className={classNames("bg-white pt-6 overflow-hidden", {
                  "rounded-[15px]  shadow-[0_3px_5px_0_rgba(9,30,66,0.10)]":
                    !isMobile,
                })}
              >
                <div className="px-4 sm:px-6">
                  <h2 className="text-lg font-[600] leading-6 text-gray-900">
                    {t("identity.profile.TwoStepVerification")}
                  </h2>
                </div>
                <div className="mt-6 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden border-t border-gray-200">
                        <div className="px-6 pt-4 !pb-9 grid grid-cols-1 gap-y-6">
                          <p className="text-base font-[600] leading-6 text-gray-900">
                            {t(
                              "identity.profile.SecureYourLinkedInAccountWithTwoStepVerification"
                            )}
                          </p>

                          <div className="flex flex-row space-x-4">
                            <img
                              className="w-[44px] h-[44px]"
                              src="../../../img/TwoStepVerification.png"
                            />

                            <div className="text-[16px] font-[400] leading-6 text-[#828A96]">
                              {t(
                                "identity.profile.TwoStepVerificationGivesYouAdditionalSecurity"
                              )}
                              {/* <span className="text-[#1A5DBC] underline cursor-pointer">
                                {t("identity.profile.LearnMore")}
                              </span> */}
                            </div>
                          </div>

                          <div className="flex flex-row space-x-4">
                            <img
                              className="w-[52px] h-[52px]"
                              src="../../../img/AuthenticatorApp.png"
                            />

                            <div className="text-[16px] font-[400] leading-6 text-[#828A96]">
                              {t(
                                "identity.profile.YourPhoneNumberOrAuthenticatorApp"
                              )}
                              {/* <span className="text-[#1A5DBC] underline cursor-pointer">
                                {t("identity.profile.LearnMore")}
                              </span> */}
                            </div>
                          </div>

                          <button
                            onClick={() => setStep(2)}
                            className="w-fit h-fit py-2 px-10 inline-flex justify-center rounded-md bg-[#1A5DBC]  text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                          >
                            {t("identity.basicProfileInfo.SetUp")}
                          </button>

                          <div className="text-[14px] font-[300] leading-6 text-[#828A96]">
                            {t("identity.profile.Note")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EditTwoStepVerificationSetUp
              twoStepProvider={twoFactorProvider}
              twoFactorEnabled={twoFactorEnabled}
              hasPassword={props.hasPassword}
              setStep={setStep}
              setQrcode={setQrcode}
              setTwoFactorEnabled={() => setTwoFactorEnabled(false)}
            />
          )}
        </>
      ) : step == 2 ? (
        <>
          <TwoStepVerificationSetUp
            hasPassword={props.hasPassword}
            twoFactorProvider={twoFactorProvider}
            setTwoFactorProvider={setTwoFactorProvider}
            twoFactorEnabled={twoFactorEnabled}
            nextStep={5}
            setStep={setStep}
            setQrcode={setQrcode}
          />
        </>
      ) : step == 3 ? (
        <TwoStepVerificationPhoneNum
          hasPassword={props.hasPassword}
          setShowTimer={setShowTimerResendCode}
          setStep={setStep}
          setPhoneNumber={setPhoneNumber}
        />
      ) : step == 4 ? (
        <TwoStepVerificationPhoneNumVerify
          twoStep={true}
          showTimer={showTimerResendCode}
          setShowTimer={setShowTimerResendCode}
          setStep={setStep}
          step={step}
          twoFactorProvider={twoFactorProvider}
          phoneNumber={phoneNumber}
          setTwoFactorEnabled={() => setTwoFactorEnabled(true)}
        />
      ) : step == 5 ? (
        <AuthenticatorApp qrcode={qrcode} setStep={setStep} />
      ) : step == 6 ? (
        <TwoStepVerificationPhoneNumVerify
          twoStep={true}
          showTimer={showTimerResendCode}
          setShowTimer={setShowTimerResendCode}
          setStep={setStep}
          step={step}
          twoFactorProvider={TwoFactorProvider.GoogleAuthenticator}
          phoneNumber={null}
          setTwoFactorEnabled={() => setTwoFactorEnabled(true)}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default TwoStepVerificationForm;
