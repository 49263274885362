import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { AccountClient, TwoFactorProvider } from "../../../swagger";
import toast, { Toaster } from "react-hot-toast";
import { axiosInstance } from "../../../services/AxiosInstance";
import EnterEmail from "../../../components/login/EnterEmail";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Password from "../../../components/login/Password";
import CreatePassword from "../../../components/login/CreatePassword";
import CompletePersonalInfo from "../../../components/login/CompletePersonalInfo";
import { useTranslation } from "react-i18next";
import VerifyCode from "../../../components/login/VerifyCode";
import { useNavigate } from "react-router-dom";

const MainPage = (props) => {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({ email: "", password: "" });
  const authResult = new URLSearchParams(props.redirectUrl);
  const redirectUrl = authResult.get("ReturnUrl");
  const [provider, setProvider] = useState<null | TwoFactorProvider>(null);
  const { t } = useTranslation();


  const onBackClick = () => {
    if (step == 1) {
      const decodedString = decodeURIComponent(redirectUrl);
      const isVoxdas = decodedString.includes("www.voxdash.com");

      // const currentUrl = window.location.href;
      // const baseUrl = currentUrl.split("?")[0];
      // const query = currentUrl.split("?")[1];
      // const updatedQuery = query ? `${query}&cancel=true` : "cancel=true";

      // const finalUrl = `${baseUrl}?${updatedQuery}`;
      // window.history.pushState({}, "", finalUrl);
      if(isVoxdas){
        window.location.replace(process.env.REACT_APP_PUBLIC_URL + "/login?cancel=true");
      }else{
        window.location.replace(process.env.REACT_APP_DATAPROVIDER_URL + "/auth/signin?cancel=true");
      }
      
    } else {
      setStep(1);
    }
  };

  const renderBack = () => {
    return (
      <>
        <svg
          className="float-right flex w-5 h-5 ml-2 -mr-1 rotate-180 text-blue-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="font-bold text-sm text-blue-500">
          {t("identity.login.back")}
        </div>
      </>
    );
  };

  return (
    <>
      <Fragment>
        <head>
          <title>{t("identity.login.loginToYourAccount")}</title>
        </head>
        <Toaster></Toaster>
        {step == 4 ? (
          <CompletePersonalInfo
            redirectUrl={redirectUrl}
            form={form}
            setStep={setStep}
          />
        ) : (
          <>
            <div
              onClick={() =>
                window.location.replace(process.env.REACT_APP_PUBLIC_URL)
              }
              className="absolute top-0 sm:hidden flex right-0 cursor-pointer"
            >
              <span className="icon-close font-bold text-2xl text-blue-500 cursor-pointer"></span>
            </div>
            <div className="mx-auto grid place-items-center min-h-screen  ">
              <div className="organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
                <div className="sizeHandler">
                  <div className="signTop organizer clearfix">
                    <div className="flex relative gap-x-6 place-items-center organizer loginForm fln m0a grid-6 grid-t-12 ">
                      <div
                        onClick={onBackClick}
                        className="flex-row justify-center items-center space-x-2 w-fit h-fit absolute sm:top-0 left-0 cursor-pointer hidden sm:flex"
                      >
                        {renderBack()}
                      </div>

                      <div
                        // onClick={() =>
                        //   window.location.replace(
                        //     process.env.REACT_APP_PUBLIC_URL
                        //   )
                        // }
                        onClick={onBackClick}
                        className="flex-row justify-center items-center space-x-2 w-fit h-fit absolute sm:top-0 right-0 cursor-pointer hidden sm:flex"
                      >
                        <span className="icon-close font-bold text-base text-blue-500 cursor-pointer"></span>
                      </div>

                      <div className=" px-8 registerImage sm:block hidden border-r-2 border-grey-50 leftFloat centerFlex halfColumn xl:w-[40vw] w-[50vw]">
                        <div className="centerKeeper organizer">
                          <img src="/img/sign.svg" className="h-80" />
                        </div>
                      </div>
                      <div className="w-full rightFloat halfColumn leftBorder hv col-auto">
                        <div className="centerKeeper textInCenter organizer flex flex-col sm:px-8  gap-x-6 gap-y-2 text-center font-semibold ">
                          <Link to="/">
                            <a>
                              <img
                                src="/img/Voxdash.svg"
                                alt="VoxDash"
                                className="mx-auto h-12 w-150 flex justify-center"
                              />
                            </a>
                          </Link>
                          {step == 1 ? (
                            <EnterEmail
                              redirectUrl={redirectUrl}
                              form={form}
                              setForm={setForm}
                              setStep={setStep}
                            />
                          ) : step == 2 ? (
                            <Password
                              redirectUrl={redirectUrl}
                              email={form.email}
                              setStep={setStep}
                              setProvider={setProvider}
                            />
                          ) : step == 3 ? (
                            <CreatePassword
                              form={form}
                              setForm={setForm}
                              setStep={setStep}
                            />
                          ) : step == 5 ? (
                            <VerifyCode
                              email={form.email}
                              setStep={setStep}
                              redirectUrl={redirectUrl}
                              step={step}
                              provider={provider}
                              forEmailActivation={false}
                            />
                          ) : step == 6 ? (
                            <VerifyCode
                              email={form.email}
                              setStep={setStep}
                              redirectUrl={redirectUrl}
                              step={step}
                              provider={provider}
                              forEmailActivation={true}
                            />
                          ) : (
                            <EnterEmail
                              redirectUrl={redirectUrl}
                              form={form}
                              setForm={setForm}
                              setStep={setStep}
                            />
                          )}
                          <div
                            onClick={onBackClick}
                            className="flex-row justify-center items-center space-x-2 w-full h-fit sm:top-0 left-0 cursor-pointer flex sm:hidden mt-5"
                          >
                            {renderBack()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Fragment>
    </>
  );
};

export default MainPage as any;
